@font-face {
    font-family: bebasneue-regular;
    src: url('BebasNeue-Regular.ttf');}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.container {
  margin: 0;
  text-align: center;
}

.nav {
  background-color: none;
  color: #A20C32;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2rem;
  padding: 0;
  padding-right: 40px;
  position: absolute;
  z-index: 1;
}

.site-title {
  font-size: 2rem;
  padding-top: 15px;
  padding-left: 0;
  text-align: left;
  font-family: 'Courier New', Courier, monospace;
}

.nav ul {
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  padding-top: 1%;
}

.nav p {
  font-size: 4rem;
  margin: 0;
  padding: 0;
}

.nav a {
  color: #A20C32;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1%;
  font-size: 4rem;
  font-family: bebasneue-regular;
  text-shadow: 2px 2px 3px #000;
}

.nav a:hover{
  color:#FFFFFF;
}

.nav li {
  padding-right: 0;
}

/* .nav li:hover {
  color:#FFFFFF;
} */

@media only screen and (max-device-width: 450px) {
  .nav {
    padding-right: 15px;
    gap: 0;
  }

  .site-title {
    padding-top: 0;
  }

  .nav img {
    width: 50px;
    height: 30px;
    padding-top: 6%;
  }

  .nav a {
    font-size: .9em;
  }
  .nav p {
    font-size: 1.2em;
  }
  .nav ul {
    gap: .5rem;
    padding-top: 0;
    padding-left: 0;
  }
}

/*@media only screen and (max-device-width: 410px) {
  .nav {
    padding-right: 35px;
  }

  .nav a {
    font-size: 15px;
  }
}
*/