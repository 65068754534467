.faq {
    padding-top: 200px;
}

.middle {
    margin: 0;
}

.bottom {
    margin: 0;
}

.faqimg {
    width: 40px;
    height: 40px;
    padding: 10px;
}

.faqna h3 {
    font-size: 55px;
}

.faqna ul {
    font-size: 30px;
}