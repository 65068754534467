@font-face {
    font-family: bebasneue-regular;
    src: url('fonts/BebasNeue-Regular.ttf');}

.shadow-drop-center {
	animation: shadow-drop-2-center 0.5s ease-in 0.5s both;
}

@keyframes shadow-drop-2-center {
    0% {
      transform: translateZ(0);
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
    100% {
      transform: translateZ(50px);
      box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
    }
  }

.image_top {
    background-image: url("https://live.staticflickr.com/65535/53672384491_5dce6a9370_k.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    padding: 0;
    margin: 0;
}

.head_top {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    padding-bottom: 7%;
    padding-top: 11%;
}

.certs {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 25px;
    margin: 0;
}

.cert1 {
    width: 210px;
    height: 135px;
    border-style: solid;
    border-width: 6px;
    border-radius: 0;
    border-color: #000000;
    background-color: #FFFFFF;
    justify-content: center;
}

.cert2 {
    width: 210px;
    height: 135px;
    border-style: solid;
    border-width: 6px;
    border-radius: 0;
    border-color: #000000;
    background-color: #FFFFFF;
    justify-content: center;
}

.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    color: #231F20;
    border: 7px solid #000000;
}

.contact_space {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3%;
    margin: 0;
}

.contact h4 {
    text-align: center;
    font-family: bebasneue-regular;
    justify-content: center;
    font-size: 2.2em;
    margin: 0;
    padding-top: 3%;
    padding-bottom: 4%;
}

.contact h5 {
    font-family: bebasneue-regular;
    font-size: 2em;
    text-align: center;
    justify-content: center;
    margin: 0;
    padding-bottom: 4%;
    padding-top: 4%;
    padding-left: 30px;
    padding-right: 30px;
}

#submitReviewsContainer .formGroup {
    margin-top: 20px;
}

.contact h3, #reviewsContainer h3, #submitReviewsContainer h3 {
    font-size: 2.0em;
    font-family: bebasneue-regular;
    font-weight: lighter;
    padding-right: 8px;
    padding-left: 8px;
    overflow-wrap: break-word;
}

#reviewsContainer h3, #submitReviewsContainer h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.contact h2, #reviewsContainer h2, #submitReviewsContainer h2 {
    font-family: bebasneue-regular;
    font-size: 2.2em;
}

#reviewsContainer, #submitReviewsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#reviewsContainer > div, #submitReviewsContainer > div {
    padding-top: 0px;
    margin: 20px;
    flex-direction: column;
    justify-content: space-evenly;
    background-color: #FFFFFF;
    color: #231F20;
    border: 10px solid #000000 ;
}

.formGroup {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
}

.formGroup input {
    height: 40px;
    font-size: 20px;
}

.formGroup textarea {
    font-size: 20px;
}

.star-rating {
    display: flex;
    flex-direction: row;
}

.star-rating {
    background: none;
    border: none;
    outline: none;
    padding: 0px !important;
    margin: 0px !important;
    font-size: 40px;
}

.star-rating div {
    max-width: fit-content;
    max-height: fit-content;
}

.star-rating .on .star {
    color: gold;
}

.star-rating .off .star {
    color: grey;
}

#submitReview {
    background-color: rgb(162, 12, 50);
    font-size: 50px;
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.title {
    display: flex;
    flex-direction: column;
    font-family: bebasneue-regular;
    padding: 0;
    margin: 0;
    gap: 0;
    align-items: center;
}

.title_content {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 5%;
    margin: 0;
    align-items: center;
}

.title_content h2 {
    font-size: 6.8em;
    text-shadow: 3px 3px 5px #000;
    color: #A20C32;
    padding: 0;
    margin: 0;
    line-height: 90%;
}

.title_content p {
    font-size: 2.8em;
}

.projects {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-bottom: 4%;
}

.projects_title {
    text-align: left;
    padding-left: 4%;
    font-family: bebasneue-regular;
    font-size: 2em;
    border-top: 20px solid #A20C32;
}

.outer_box {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    padding-bottom: 4%;
    z-index: 1;
}

.img_slider{
    width: 75%;
    height: 100%;
    text-align: center;
    border-style: solid;
    border-color: black;
    border-width: .5em;
    z-index: -1;
    overflow: hidden;
}

.tesla_wall {
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-style: solid;
    border-color: black;
    border-width: .5em;
}

.tesla_wall iframe {
    vertical-align: bottom;
}

.span_flyer {
    padding-top: 4%;
    justify-content: center;
}

.learn_back {
    background-image: url("https://live.staticflickr.com/65535/53695335389_ea80a856e9_k.jpg");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: stretch;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

.learn {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.learn_title {
    text-align: left;
    padding-left: 5%;
    color:#A20C32;
    font-family: bebasneue-regular;
    font-size: 2em;
}

.learn_contents {
    display: flex;
    flex-direction: row;
    gap: 140px;
}

.learn_vidoes {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 50px;
    padding-top: 1%;
    padding-bottom: 4%;
    padding-left: 5%;
}

.learn_questions {
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 35px;
    padding-bottom: 3%;
    padding-left: 6.5%;
}

.q {
    background-color: #A20C32;
    border-radius: 50%;
    color:#F9F8F9;
    padding-top: 3%;
    padding-bottom: 5%;
    width: 65%;
    height: 65%;
    align-items: center;
    line-height: 370%;
    font-family: bebasneue-regular;
    font-size: 1.8em;
}

.future {
    display: flex;
    flex-direction: column;
    width: 100%; 
    align-items: center;
    justify-content: space-around;
    gap: 40px;
    padding-bottom: 3.5%;
    padding-left: 3.5%;
    padding-right: 3.5%;
}

.future_title {
    text-align: left;
    padding-left: 4%;
    font-family: bebasneue-regular;
    font-size: 2em;
}

.future h2 {
    font-family: bebasneue-regular;
    font-size: 4rem;
    text-shadow: 2px 2px 3px #000;
    color: #A20C32;
}

#future_subtitle {
    font-family: bebasneue-regular;
    font-size: 3rem;
    color: #000000;
}

.future_top {
    display: flex;
    flex-direction:column;
    font-family: bebasneue-regular;
    font-size: 2rem;
    background-color: #A20C32;
    color:#FFF;
    padding-left: 5%;
    padding-right: 5%;
}

.future_middle {
    display: flex;
    flex-direction: column;
    font-family: bebasneue-regular;
    font-size: 1.5rem;
    background-color: #BBBBBB;
    color:#000000;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 2%;
}

.future ul {
    text-align: left;
}

.future_bottom {
    display: flex;
    flex-direction: column;
    font-family: bebasneue-regular;
    font-size: 2em;
    background-color: #A20C32;
    color:#FFF;
    padding-left: 5%;
    padding-right: 5%;
}

.add_services {
    text-align: center;
    font-family: bebasneue-regular;
}

.add_services p {
    font-size: 3.75rem;
    text-shadow: 2px 2px 2px #000;
    color: #A20C32;
}

.add_services h2 {
    font-size: 2.5rem;
    text-shadow: 2px 2px 2px #000;
    color: #FFFFFF;
}

.services {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 5%;
    width: 100%;
}

.serv_items {
    display: flex;
    flex-direction: column;
    padding: 2%;
    background-color: #BBBBBB;
    border-width: 2px;
    border-color: #A20C32;
    border-style: solid;
    text-align: center;
    justify-content: center;
}

#a {
    background-image: url("https://live.staticflickr.com/65535/53756587753_7b7e877562_w.jpg");
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}

#b {
    background-image: url("https://live.staticflickr.com/65535/53852443228_10c286a0a1_w.jpg") ;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}

#c {
    background-image: url("https://live.staticflickr.com/65535/53867471124_00275eae52_w.jpg") ;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}

#d {
    background-image: url("https://live.staticflickr.com/65535/53756802860_09911ab397_w.jpg");
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
}

.review {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #BBBBBB;
}

.review_title {
    text-align: left;
    padding-left: 40px;
    padding-top: 2%;
    color:#A20C32;
    font-family: bebasneue-regular;
    font-size: 30px !important;
}

.review_title h2 {
    margin-top: 0px;
    margin-bottom: 0px;
}

.reach_out {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 1%;
    align-items: center;
    padding-top: 5%;
}

.b1_box {
    display: flex;
    flex-direction: row;
    color:#000000;
    background-color: #FFFFFF;
    width: 90%;
    justify-content: space-around;
    padding: 1%;
    font-family: bebasneue-regular;
    align-items: center;
}

.b1_box h2 {
    font-size: 3.5em;
}

.b1 {
    background-color: #A20C32;
    border-radius: 10px;
    padding: 0.5%;
}

.b1 a {
    font-size: 5.5em;
    color:#FFFFFF;
    padding: 10px;
}

.add_link {
    display: flex;
    flex-direction: column;
    font-size: 3em;
    font-family: bebasneue-regular;
    width: 100%;
    padding-bottom: 2%;
    text-align: center;
}

.add_link_text {
    padding-top: 1%;
}

@media only screen and (max-device-width: 450px) {

    .title_content h2 {
        font-size: 3em;
    }

    .title_content p {
        font-size: 1.25em;
    }     
    
    .contact {
        border-width: .15em;
    }

    .contact h3 {
        font-size: .35em;
        padding-right: 2px;
        padding-left: 2px;
        line-height: 0;
    }

    .contact h2 {
        font-size: .5em;
        padding-top: 0;
    }
    .contact h4 {
        font-size: .65em;
    }
    
    .contact h5 {
        font-size: 0.45em;
        padding-left: 8px;
        padding-right: 8px;
    }

    .certs img {
        width: 35px;
        height: 25px;

    }

    .cert1 {
        width: 38px;
        height: 25px;
        border-width: .1em;
    }

    .cert2 {
        width: 38px;
        height: 25px;
        border-width: .1em;
    }

    .title_content h2 {
        font-size: 1.75em;
    }
    
    .title_content p {
        font-size: .8em;
    }

    .projects_title {
        font-size: .6em;
        border-top: 5px solid #A20C32;
    }
    
    .img_slider {
        height: 150px;
        border-width: .15em;
    }

    .tesla_wall {
        border-width: .15em;
    }
    .tesla_wall iframe {
        width: 240px;
        height: 135px;
    }

    .span_flyer img {
        width: 280px;
        height: 350px;
        justify-content: center;
    }

    .learn_back {
        background-image: url("https://live.staticflickr.com/65535/53695335389_2e300e9b40_z.jpg");
    }
    
    .learn_title {
        font-size: .6em;
    }
    
    .learn_contents {
        gap: 0px;
    }

    .learn_vidoes iframe {
        width: 100px;
        height: 75px;
    }
    
    .learn_questions {
        gap: 35px;
    }
    
    .q {
        padding-top: 3%;
        padding-bottom: 5%;
        width: 65%;
        height: 65%;
        line-height: 170%;
        font-size: .5em;
    }

    .future {
        gap: 40px;
        padding-bottom: 5%;
    }
    
    .future_title {
        font-size: .6em;
    }

    #future_subtitle {
        font-size: 1.25rem;
    }

    .future h2 {
        font-size: 1.7rem;
        text-shadow: 1px 1px 2px #000;
    }
    
    .future_top {
        font-size: 1.5rem;
    }
    
    .future_middle {
        font-size: 1.25rem;
        padding-top: 0;
    }
    
    .future_bottom {
        font-size: 1.5em;
    }
    
    .add_services p {
        font-size: 2.6rem;
        text-shadow: 2px 2px 2px #000;
    }
    
    .add_services h2 {
        font-size: 1.25rem;
        text-shadow: 1px 1px 2px #000;
    }
    
    .services {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        gap: 3%;
        width: 100%;
    }

    .serv_items {
        height: 200px;
    }

    .review_title {
    font-size: .5em;
    }

    .review_content {
        font-size: .75em;
    }
    
    .star-rating button {
        font-size: .7em;
    }

    .formGroup input {
        font-size: 10px;
    }
    
    .formGroup textarea {
        font-size: 10px;
    }

    .contact h3, #reviewsContainer h3, #submitReviewsContainer h3 {
        font-size: 1.1em;
    }
    #submitReview {
        font-size: 20px;
    }

    .reach_out {
        width: 100%;
        padding-bottom: 2.5%;
    }
    .b1_box {
        gap: 5px;
    }

    .b1_box h2 {
        font-size: 1.5em;
    }
    
    .b1 {
        border-radius: 8px;
        padding: 0.5%;
    }
    
    .b1 a {
        font-size: 2em;
        padding: 3px;
    }
    .add_link{
        font-size: 1.3em;
        align-items: center;
    }
    .add_link a {
        font-size: .9em;
    }
}
